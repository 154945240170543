import React from 'react';

import Banner from 'web/components/banner';

export const BANNER_PATH = '/fresh-picks ';

const HomeBanner: React.FC = () => {
  const handleClickCard = (): void => {
    window.location.assign(BANNER_PATH);
  };

  return (
    <div className="basket-view__header home-banner">
      <Banner
        onClickInfo={() => handleClickCard()}
        title={
          <p>
            Welcome to Good Eggs! Get 20% off your first four orders as a new user. Use code
            HelloGoodEggs at checkout.&nbsp;
            <a href={BANNER_PATH}>Click here</a>
          </p>
        }
      />
    </div>
  );
};

export default HomeBanner;
