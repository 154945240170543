import React, {FC, PropsWithChildren, ReactElement, ReactNode} from 'react';

import assetPath from 'web/helpers/asset_path';

export interface BannerProps {
  onClickInfo?: () => void;
  title: ReactNode;
}

const Banner: FC<PropsWithChildren<BannerProps>> = ({onClickInfo, title}): ReactElement => {
  return (
    <button className="banner new-banner-style" type="button">
      <img
        onClick={onClickInfo}
        alt="Banner logo"
        data-testid="banner-logo"
        src={`${assetPath('/img/svg/icons/ge-g-logo-white.svg')}?auto=format`}
        className="banner__icon"
        style={{cursor: onClickInfo ? 'pointer' : 'inherit'}}
      />
      {title}
      <i
        data-testid="info-icon"
        className="icon icon-alert-info"
        onClick={onClickInfo}
        style={{cursor: onClickInfo ? 'pointer' : 'inherit'}}
      />
    </button>
  );
};

export default Banner;
